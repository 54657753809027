<template>
  <div>
    <b-container fluid="xl" class="pt-4 pt-lg-5">
      <h1 class="page-title text-center">{{ pageData.title }}</h1>
    </b-container>
      
    <b-container>
      <b-row>
        <b-col md="12 mt-2 mt-lg-3">
          <p style="text-align: center; font-size:11pt;">
            We invite high-caliber individuals who are enthusiastic, innovative and progressive to be part of our establishment<br>
            and to develop a rewarding and satisfying career with us.</p>

          <p style="text-align: center; font-size:11pt;">Below are available vacancies:</p>
        </b-col>

        <b-col md="12">
          <div class="accordion" role="tablist">
            <b-card no-body class="mb-1 card-career">
              <b-card-header header-tag="header" class="p-0" role="tab">
                <b-button block v-b-toggle.accordion-1 variant="secondary" size="lg" class="rounded-0 text-left btn-yellow btn-position-title d-flex justify-content-between px-4">
                  <span>Position 1 / Job Title: Senior Executive / Executive (Township Management Division)</span> 
                  <span class="when-opened align-self-center">
                    <b-icon icon="chevron-compact-up"></b-icon>
                  </span>
                  <span class="when-closed align-self-center">
                    <b-icon icon="chevron-compact-down"></b-icon>
                  </span>
                </b-button>
              </b-card-header>
              <b-collapse id="accordion-1" visible accordion="accordion-1" role="tabpanel">
                <b-card-body>
                  <p style="text-align:justify"><strong><span style="font-size:11pt">Requirement: -</span></strong></p>

                  <p style="text-align:justify"><span style="font-size:11pt"><b>(A).&nbsp; Qualification and experience: -</b></span></p>

                  <ol style="list-style-type:lower-alpha; font-size:11pt;">
                    <li style="text-align:justify; margin-left:8px"><span style="font-size:11pt">Degree/Diploma in Building, Property Management or Civil Engineering.</span></li>
                    <li style="text-align:justify; margin-left:8px"><span style="font-size:11pt">Minimum 2 years of site experience in related construction work and 1 year experience in handling customers’ feedback/complaints</span></li>
                    <li style="text-align:justify; margin-left:8px"><span style="font-size:11pt">Well versed in construction techniques &amp; issues and experience in handling customers’ feedback/complaints.</span></li>
                    <li style="text-align:justify; margin-left:8px"><span style="font-size:11pt">Able to co-ordinate assignments involving various parties.</span></li>
                    <li style="text-align:justify; margin-left:8px"><span style="font-size:11pt">Able to work on weekends.</span></li>
                    <li style="text-align:justify; margin-left:8px"><span style="font-size:11pt">Able to work as a team.</span></li>
                    <li style="text-align:justify; margin-bottom:11px; margin-left:8px"><span style="font-size:11pt">Possess a valid driving license.</span></li>
                  </ol>

                  <p class="mt-5" style="text-align:justify"><span style="font-size:11pt"><b>(B). Key Responsibilities</b></span></p>

                  <ol style="font-size:11pt;">
                    <li style="text-align: justify; margin-left: 8px;"><span style="font-size:11pt">To comply and achieve customer satisfaction in the handover procedure of all properties.</span></li>
                    <li style="text-align: justify; margin-left: 8px;"><span style="font-size:11pt">Attend to complaints and ensure that follow-up action is taken to address the complaints within the time frame.</span></li>
                    <li style="text-align: justify; margin-left: 8px;"><span style="font-size:11pt">Deliver high quality customer services.</span></li>
                    <li style="text-align: justify; margin-left: 8px;"><span style="font-size:11pt">Supervise, monitor workers in carrying out defect rectification works.</span></li>
                    <li style="text-align: justify; margin-left: 8px;"><span style="font-size:11pt">Check, monitor, and follow up on the quality of defect rectification works.</span></li>
                    <li style="text-align: justify; margin-left: 8px;"><span style="font-size:11pt">To check the quality of work of new project construction site to ensure compliance of quality before handing over.</span></li>
                    <li style="text-align: justify; margin-left: 8px;"><span style="font-size:11pt">To prepare QA/QC &amp; defect rectification reports.</span></li>
                    <li style="text-align: justify; margin-left: 8px;"><span style="font-size:11pt">To assist with the handing over of keys and other items to purchasers.</span></li>
                    <li style="text-align: justify; margin-left: 8px;"><span style="font-size:11pt">Handling the general repair and maintenance of the Company’s corporate office and gated communities.</span></li>
                    <li style="text-align: justify; margin-bottom: 11px; margin-left: 8px;"><span style="font-size:11pt">Handling the gated communities and stratified management.</span></li>
                  </ol>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
        </b-col>

        <b-col md="12" class="my-3 d-none">
          <div class="accordion" role="tablist">
            <b-card no-body class="mb-1 card-career">
              <b-card-header header-tag="header" class="p-0" role="tab">
                <b-button block v-b-toggle.accordion-2 variant="secondary" size="lg" class="rounded-0 text-left btn-yellow btn-position-title d-flex justify-content-between px-4">
                  <span>Position 2 / Job Title: Account Executive</span> 
                  <span class="when-opened">
                    <b-icon icon="chevron-compact-up"></b-icon>
                  </span>
                  <span class="when-closed">
                    <b-icon icon="chevron-compact-down"></b-icon>
                  </span>
                </b-button>
              </b-card-header>
              <b-collapse id="accordion-2" accordion="accordion-2" role="tabpanel">
                <b-card-body>
                  <p style="margin-left:7px; margin-right:-1px"><span style="font-size:11pt"><strong>Job Responsibilities</strong></span></p>

                  <ol>
                    <li><span style="font-size:11pt">Maintain proper ledgers to ensure accuracy &amp; completeness for preparation of full set of accounts</span></li>
                    <li><span style="font-size:11pt">Cross check monthly AR &amp; AP control accounts</span></li>
                    <li><span style="font-size:11pt">Liaise with auditors &amp; tax agents for interim/final audit &amp; corporate tax filing</span></li>
                    <li><span style="font-size:11pt">Liaise &amp; coordinate with sales &amp; other departments for relevant information &amp; documentation to finalize accounts</span></li>
                    <li><span style="font-size:11pt">Assist in all finance, audit and tax related matters</span></li>
                    <li><span style="font-size:11pt">Any other ad-hoc matters as assigned by superior</span></li>
                  </ol>

                  <p>&nbsp;</p>

                  <p style="margin-left:7px; margin-right:-1px"><span style="font-size:11pt"><strong>Requirements</strong></span></p>

                  <ol>
                    <li><span style="font-size:11pt">Association of Chartered Certified Accountants (ACCA) or Bachelor&rsquo;s degree in accounting</span></li>
                    <li><span style="font-size:11pt">Audit experience in Big Four auditing film (At least 2 - 3 years) or, 2 years of experience working as a GL Accountant, or a similar role in the accounting department.</span></li>
                    <li><span style="font-size:11pt">Good analytical and problem-solving skills</span></li>
                    <li><span style="font-size:11pt">Strong attention to detail, excellent organizational skills and ability to effectively prioritize work to meet deadlines.</span></li>
                  </ol>

                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
        </b-col>

        <b-col md="12 my-5">
          <p style="text-align: center;">
            <span style="font-size:11pt"><span>Please send your CV with present &amp; expected salary and&nbsp;&nbsp;latest photograph to the address below :-</span><br>
            <span style="font-size:11pt"><span class="text-yellow"><b>The Human Resource and Administration Manager<br>
            Wisma Impian Emas, No.55, Jalan Impian Emas 5/1, Taman Impian Emas,<br>
            81300 Skudai, Johor</b></span>
          </span></span></p>

          <p style="text-align: center;"><span style="font-size:11pt"><span><span style="color: black;">OR</span></span></span></p>

          <p style="text-align: center;"><span style="font-size:11pt"><span>by e-mailing your application to : <br><span class="text-yellow">hr@impianemas.my</span></span></span></p>

          <p style="text-align: center;"><span style="font-size:11pt">(Only short listed candidates will be notified)</span></p>
        </b-col>

      </b-row>

    </b-container>
  </div>
</template>

<script>

// @ is an alias to /src
export default {
  name: 'Enquiry',
  props: {
    pageData: Object
  },
  components: {
  }
}
</script>


<style scoped>
  .page-title {
    color: #e8a713;
  }

  .collapsed > .when-opened,
  :not(.collapsed) > .when-closed {
    display: none;
  }

  .btn-position-title {
    border-top-left-radius: 12px !important;
    border-top-right-radius: 12px !important;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }

  .card-career {
    border-radius: 12px;
  }

  @media only screen and (max-width: 768px) {
    .btn-position-title {
      font-size: 14pt;
    }
  }
</style>