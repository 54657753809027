<template>
  <header>
    <Navbar :header="header"/>
  </header>
</template>

<script>
import Navbar from '@/components/Navbar'

export default {
  components: {Navbar},
  data () {
    return {
      header: {}
    }
  },
  created () {
    this.$axios
      .get(this.$apiUrl+'/header?token='+this.$apiToken)
      .then(response => {
        this.header = response.data.header;
        // console.log(this.header);
      }).catch(error => {
        // console.log('There was an error:' + error.response)
      })
  }
}

</script>
