<template>
    <div>
      <b-container fluid="xl" class="pt-5">
        <h1 class="page-title text-center">{{ pageData.title }}</h1>
      </b-container>
     
      <section v-for="section in pageData.sections" :key="section.name" class="bg-yellow">
        <div v-if="section.section_type.code == 'Article'">
          <div class="container">
            <b-row class="mb-5">
              <div class="col-md-12 mt-4 text-white pb-2" v-for="content in section.contents" :key="content.title">
                <Article :content="content"/>  
              </div>
            </b-row>
          </div>
        </div>

        <div v-else></div>
      </section>

      <section>
        <b-container>
          <b-row class="enquiry-form-row">
            <b-col md="8">
              <EnquiryForm/>
            </b-col>
            <b-col md="4">
              <EnquirySidebar/>
            </b-col>
          </b-row>
        </b-container>
      </section>

      <!-- <Section v-for="section in pageData.sections" :key="section.name" :section="section"/> -->
    </div>
</template>

<script>

// @ is an alias to /src
import Section from '@/views/Section.vue'
import Article from '@/components/Article.vue'
import EnquiryForm from '@/components/EnquiryForm.vue'
import EnquirySidebar from '@/components/EnquirySidebar.vue'

export default {
  name: 'Enquiry',
  props: {
    pageData: Object
  },
  components: {
    Section,
    Article,
    EnquiryForm,
    EnquirySidebar,
  }
}
</script>


<style scoped>
  .page-title {
    color: #e8a713;
  }

  .enquiry-form-row {
    margin-top: -110px;
  }
</style>