<template>
  <div>
    <b-jumbotron :header="content.title" header-level="4" lead="" class="text-center">
      <!-- <p>For more information visit website</p> -->
      <b-button variant="primary" size="lg" v-bind:to="content.button_url">{{ content.button_name }}</b-button>
    </b-jumbotron>
  </div>
</template>

<script>
  export default {
    name: 'CallToAction',
    props: {
      content: Object
    }
  }
</script>