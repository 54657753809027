<template>
  <div>
    <b-modal v-if="videoIntro" ref="video-intro-modal" id="video-intro-modal" hide-footer hide-header> 
      <div class="facebook-responsive">
        <iframe 
          width="560" 
          height="315"
          src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2Fimpianemas.my%2Fvideos%2F1572913826420025%2F&show_text=false&autoplay=false&control=false" 
          style="border:none;" 
          scrolling="no"
          allowTransparency="true"
          frameborder="0" 
          allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" 
          allowFullScreen="true">
        </iframe>
      </div>
      <div class="modal-overlay">
        <a href="javascript:void(0)" @click="closeVideoIntroModal()">Skip&nbsp;<b-icon icon="chevron-right"></b-icon></a>
      </div>
    </b-modal>

    <div v-if="videoIntro" class="video-intro-mobile d-block d-sm-none">
      <div class="d-flex justify-content-center align-items-center h-100">
        <div class="embed-responsive embed-responsive-16by9">
          <iframe 
            class="embed-responsive-item"
            width="560" 
            height="315"
            src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2Fimpianemas.my%2Fvideos%2F1572913826420025%2F&show_text=false&autoplay=false&control=false" 
            style="border:none;" 
            scrolling="no"
            allowTransparency="true"
            frameborder="0" 
            allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" 
            allowFullScreen="true">
          </iframe>
        </div>
        <div class="video-intro-mobile-overlay float-right">
          <a href="#" @click="closeVideoIntro()">Skip&nbsp;<b-icon icon="chevron-right"></b-icon></a>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
  export default {
    name: 'VideoIntro',
    data () {
      return {
        videoIntro: true,
      }
    },
    mounted() {
      const isMobile = window.matchMedia("only screen and (max-width: 760px)").matches;
      if (!isMobile) {
         this.$refs['video-intro-modal'].show()
      }
    },
    methods: {
      closeVideoIntroModal() {
        this.videoIntro = false;
        this.$refs['video-intro-modal'].hide()
      },
      closeVideoIntro() {
        this.videoIntro = false;
      }
    }
  }
</script>

<style scoped>
/* #video-intro-modal___BV_modal_outer_ {
  z-index: 99999 !important;
}

#video-intro-modal {
  padding: 0px !important;
}

#video-intro-modal .modal-dialog {
    width: 100%;
    max-width: 100% !important;
    height: 100%;
    margin: 0;
}

#video-intro-modal .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
}

#video-intro-modal .modal-body {
  margin: 0;
  padding: 0;
  background-color: #333;
}

.video-intro-mobile {
  background: #000;
  height: 75vh;
}

.video-intro-mobile-overlay {
  padding-top: 5px;
  position: relative;
  text-align: right;
}

.video-intro-mobile-overlay a {
  color: #fff;
  text-decoration: none;
}

.facebook-responsive {
    overflow:hidden;
    position:relative;
    height:0;
}

.facebook-responsive iframe {
    left:0;
    top:0;
    height:100%;
    width:100%;
    position:absolute;
}

@media screen and (max-width:640px) {
  div#block-yui_3_17_2_1_1596082723574_4344 iframe {
      height: auto;
  }
}

@media only screen and (max-width: 767.98px) {
  #video-intro-modal .modal-body {
    background-color: #333;
  }
}

@media (min-width: 576px) {
  #video-intro-modal .modal-dialog {
      width: 100% !important;
      max-width: 100% !important;
      height: 100% !important;
      margin: 0;
  }
}

#video-intro-modal .modal-overlay {
  position:fixed;
  bottom: 10px;
  right:25px;
}

#video-intro-modal .modal-overlay a {
  color: #fff;
  font-size: 30px;
} */

</style>