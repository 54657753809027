<template>
  <div class="layout-default">
    <!-- Header -->
    <Header/>

    <!-- Main Content -->
    <main  role="main" class="flex-shrink-0">
      <slot/>
    </main>


    <!-- Footer -->
    <Footer/>
  </div>
</template>

<script>

import Header  from '@/views/Header'
import Footer  from '@/views/Footer'

export default {
  components: { Header, Footer }
}
</script>

<style scoped>
  .layout-default {
    display: flex !important;
    flex-direction: column !important;
    height: 100% !important;
  }
</style>