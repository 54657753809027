<template>
  <div>

    <b-card id="card-enquiry-form" class="mb-5 p-3">

      <b-alert variant="success" :show="alertMsgSuccess">
        <h3 class="alert-heading">Enquiry submitted succesfully!</h3>
        <p>Thank for your time to fill the enquiry form, Our staff representative will respond promptly to your request.</p>
      </b-alert>

      <b-alert variant="danger" class="alert-error" :show="alertMsgError">
        <span class="alert-msg">Failed to submit enquiry, please try again later.</span>
      </b-alert>

      <b-form @submit="onSubmit" @reset="onReset" v-if="showForm">
        <h4 class="text-center">Registration Form</h4>

          <b-form-group
            id="input-group-name"
            label="Name"
            label-for="name"
          >
            <b-form-input
              id="name"
              v-model="form.name"
              type="text"
              placeholder="Enter name"
              :state="formValidate.name.state"
              required
            ></b-form-input>
            <b-form-invalid-feedback>{{ formValidate.name.message }}</b-form-invalid-feedback>
          </b-form-group>  

        <b-row>
          <b-col md="6">
            <b-form-group
              id="input-group-email"
              label="Email address"
              label-for="email"
            >
              <b-form-input
                id="email"
                v-model="form.email"
                type="email"
                placeholder="Enter email address"
                :state="formValidate.email.state"
                required
              ></b-form-input>
            <b-form-invalid-feedback>{{ formValidate.email.message }}</b-form-invalid-feedback>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group id="input-group-phone-number" label="Tel" label-for="phone-number">
              <b-form-input
                id="phone-number"
                v-model="form.phone_number"
                placeholder="Enter phone number"
                :state="formValidate.phone_number.state"
                required
              ></b-form-input>
              <b-form-invalid-feedback>{{ formValidate.phone_number.message }}</b-form-invalid-feedback>
            </b-form-group>
          </b-col>

        </b-row>

        <hr>

        <b-row>
          <b-col md="12">
            <b-form-group 
                id="input-group-4" 
                v-slot="{ ariaDescribedby }" 
                label="Type of Property Interest" 
                class="w-100" 
                :state="formValidate.preferred.state"
            >
              <b-form-checkbox-group
                id="checkboxes-4"
                v-model="form.preferred"
                :aria-describedby="ariaDescribedby"
              >
                <b-row>
                  <b-col sm="4">
                    <b-form-checkbox value="1-Storey Terrace House">1-Storey Terrace House</b-form-checkbox>
                  </b-col>

                  <b-col sm="4">
                    <b-form-checkbox value="2-Storey Terrace House">2-Storey Terrace House</b-form-checkbox>
                  </b-col>

                  <b-col sm="4">
                    <b-form-checkbox value="2-Storey Terrace House">2-Storey Cluster House</b-form-checkbox>
                  </b-col>
                  
                  <b-col sm="4">
                    <b-form-checkbox value="2-Storey Semi-Detached">2-Storey Semi-Detached</b-form-checkbox>
                  </b-col>
                  
                  <b-col sm="4">
                    <b-form-checkbox value="Apartment / Condominium">Apartment / Condominium</b-form-checkbox>
                  </b-col>

                  <b-col sm="4">
                    <b-form-checkbox value="2-Storey Detached (Bungalow)">2-Storey Detached (Bungalow)</b-form-checkbox>
                  </b-col>
                  
                  <b-col sm="4">
                    <b-form-checkbox value="2-Storey / 3-Storey Shop Office">2-Storey / 3-Storey Shop Office</b-form-checkbox>
                  </b-col>

                </b-row>
              </b-form-checkbox-group>
            </b-form-group>
            <b-form-invalid-feedback>{{ formValidate.preferred.message }}</b-form-invalid-feedback>
          </b-col>

        </b-row>


        <div class="py-4">
          <p class="text-justify"><small>By completing this Priority Registration Form, I hereby understand, acknowledge and expressly give to consent Gunung Impian Development Sdn Bhd and/or its affiliates and/or its subsidiaries to collect and process my Personal Data in order to enable them to keep me informed of any updates/information/events/products/services with regards to Gunung Impian Development Sdn Bhd.</small></p>
						
					<p class="text-justify"><small><em>Note:<br>
						*Registration is valid for a period of one year only, afterwhich it will be auto-deleted. Booking is based on a first-come-first-serve basis.<br />
						*Please ensure all information provided is accurate. The acceptance of this form does not guarantee or imply that the property indicated will be allocated to you.</em></small></p>
        </div>

        <div class="text-center">
          <button type="submit" class="btn btn-secondary btn-yellow">Submit</button>
          <!-- <b-button type="submit" variant="primary" class="btn-yellow">Submit</b-button> -->
        </div>

      </b-form>
    </b-card>

    
  </div>
</template>


<script>

  export default {
    data() {
      return {
        showForm: true,
        alertMsgSuccess: false,
        alertMsgError: false,
        form: {
          name: '',
          email: '',
          phone_number: '',
          preferred: []
        },
        formValidate: {
          name: {
            state: null,
            message: ''
          },
          phone_number: {
            state: null,
            message: ''
          },
          email: {
            state: null,
            message: ''
          },
          preferred: {
            state: null,
            message: ''
          },
        }
      }
    },
    methods: {
      onSubmit(event) {
        event.preventDefault()
        let self = this;
        this.$axios.post(this.$apiUrl+'/submit-impianemas-registration-form?token='+this.$apiToken, this.form, { withCredentials: false, })
          .then((response) => {
            if (response.data.success == true) {
              self.alertMsgError = false;
              self.alertMsgSuccess = true;
              self.showForm = false;
            } else {
              self.alertMsgError = true;

              // validate form
              let errorMsgs = response.data.messages.errors;
              for (const [index, value] of Object.entries(errorMsgs)) {
                this.validateForm(index, value);  
              }

            }
            // console.log(response);
          })
          .catch(function (error) {
              self.alertMsgError = true;
            // console.log(error);
          });

        // console.log(JSON.stringify(this.form))
      },
      onReset(event) {
        event.preventDefault()
        // Reset our form values
        this.form.email = ''
        this.form.name = ''
        this.form.food = null
        this.form.preferred = []
        // Trick to reset/clear native browser form validation state
        this.show = false
        this.$nextTick(() => {
          this.show = true
        })
      },
      validateForm(i, msg) {
        this.formValidate[i].state = false;
        this.formValidate[i].message = msg;
      },
    }
  }
</script>

<style scoped>
  #card-enquiry-form {
    font-family: 'Trebuchet MS', sans-serif;
    border-radius: 12px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    color: #555; 
  }

  #card-enquiry-form .card-title {
    text-align: center !important;
  }

  .form-control, .custom-select {
    border-radius: 12px;
  }
</style>