<template>
  <div>
    <b-modal ref="loyalty-program-modal" id="loyalty-program-modal"  hide-footer hide-header hide-backdrop>
      <button type="button" aria-label="Close" class="close" @click="hideModal">×</button>
      <div class="d-flex h-100">
        <div class="logo-container d-flex align-items-center pl-4 pr-3">
          <div class="loyalty-program-logo"></div>
        </div>
        <div class="loyalty-program-content d-flex align-items-center px-5">
          <div class="text-center">
            <h6><strong>EARN REWARDS</strong></h6>
            <p>Enjoy exclusive cash rebates
                when you purchase property
                at Taman Impian Emas!</p>
                
            <div class="mt-3">
              <router-link to="news-events?title=Introducing-the-Emas-Loyalty-Programme" class="btn btn-secondary btn-learn-more">Learn More</router-link>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal ref="loyalty-program-mobile-modal" id="loyalty-program-mobile-modal" centered hide-footer hide-header>
      <button type="button" aria-label="Close" class="close" @click="hideModal">×</button>
      <div class="logo-container-mobile d-flex align-items-center justify-content-center pl-4 pr-3">
        <div class="loyalty-program-logo-mobile my-4"></div>
      </div>
      <div class="loyalty-program-mobile-content d-flex align-items-center px-5 py-4">
          <div class="text-center">
            <h4><strong>EARN REWARDS</strong></h4>
            <p>Enjoy exclusive cash rebates
                when you purchase property
                at Taman Impian Emas!</p>
                
            <div class="mt-3">
              <router-link to="news-events?title=Introducing-the-Emas-Loyalty-Programme" class="btn btn-secondary btn-learn-more">Learn More</router-link>
            </div>
          </div>
        </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: 'EmasLoyaltyProgram',
  mounted() {
    const isMobile = window.matchMedia("only screen and (max-width: 760px)").matches;
    if (isMobile) {
      this.$refs['loyalty-program-mobile-modal'].show()
    } else {
      this.$refs['loyalty-program-modal'].show()
    }

  },
  methods: {
      hideModal() {
        this.$refs['loyalty-program-modal'].hide()
        this.$refs['loyalty-program-mobile-modal'].hide()
      },
    },
}
</script>

<style>
  #loyalty-program-modal {
    /* position: absolute !important; */
  }

  #loyalty-program-modal .modal-dialog {
      width:20%;
      position:fixed;
      bottom:0;
      right:12px;
      margin:0;
  }

  /* #loyalty-program-modal .modal-dialog {
    width: 24.5% !important;
    position:fixed !important;
    bottom:0 !important;
    right: 25px !important;
    margin:0 !important;
  } */

  #loyalty-program-modal .modal-content {
      height: 170px;
      width: 486px;
      border-radius: 12px;    
      position: absolute;
      bottom:12px;
      right: 0;
  }

  #loyalty-program-modal .modal-body {
    padding: 0px;
  }

  #loyalty-program-mobile-modal .modal-dialog {
    display: flex;
    justify-content: center;
  }

  #loyalty-program-mobile-modal .modal-content {
      border-radius: 12px;
      width: 85%;
  }

  #loyalty-program-mobile-modal .modal-body {
    padding: 0px;
    display: flex;
    -webkit-box-orient: vertical!important;
    -webkit-box-direction: normal!important;
    -ms-flex-direction: column!important;
    flex-direction: column!important;
  }

  #loyalty-program-mobile-modal .close {
    color: #C8A559;
  }

  .logo-container-mobile {
    background: #283F3E;
    border-top-left-radius: 9px;
    border-top-right-radius: 9px;
  }

  .logo-container {
    background: #283F3E;
    border-top-left-radius: 9px;
    border-bottom-left-radius: 9px;
  }

  .loyalty-program-logo {
    height: 100px;
    width: 150px;
    background-image: url('../assets/images/emas_loyalty_prog_logo.png');
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
    image-rendering: -webkit-optimize-contrast;  
  }


  .loyalty-program-logo-mobile {
    height: 100px;
    width: 100%;
    background-image: url('../assets/images/emas_loyalty_prog_logo.png');
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
    image-rendering: -webkit-optimize-contrast;  
  }


  .loyalty-program-content {
    background: #C8A559;
    font-family: 'Times New Roman', Times, serif !important;
    color: #283F3E;
    font-weight: bolder !important;
    font-size: 11pt;
    border-top-right-radius: 9px;
    border-bottom-right-radius: 9px;
  }

  .loyalty-program-mobile-content {
    background: #C8A559;
    font-family: 'Times New Roman', Times, serif !important;
    color: #283F3E;
    font-weight: bolder !important;
    font-size: 15px;
    border-bottom-left-radius: 9px;
    border-bottom-right-radius: 9px;
  }

  .loyalty-program-mobile-content p {
    font-size: 1.35em;
    line-height: 20px;
  }


  .btn-learn-more {
    background-color: #283F3E !important;
    color: #C8A559 !important;
    font-weight: bolder !important;
    border-radius: 5px !important;
    padding: 3px 16px 3px 16px !important;
  }

  .close {
    position: absolute;
    right: 8px;
    font-size: 27px;
  }

  .close:hover {
    color: #333 !important; 
  }

  

  @media (min-resolution: 120dpi) {
    /* #loyalty-program-modal .modal-dialog {
      width: 24.5% !important;
      position:fixed !important;
      bottom:0 !important;
      right:35px !important;
      margin:0 !important;
    } */

    #loyalty-program-modal .modal-content {
        height: 150px;
        width: 400px;
        border-radius: 12px;
        position: absolute;
        overflow: hidden;
    }
    
    .loyalty-program-logo {
        height: 100px;
        width: 100px;
    }

    .loyalty-program-content {
      font-size: 9pt;
    }

  }
</style>