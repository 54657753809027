<template>
  <footer class="footer mt-auto">
    <b-container fluid>
      <b-row class="">
        <b-col md="12" class="px-0 d-lg-flex justify-content-between px-lg-0 px-3">
          <div class="pl-lg-5 align-self-center pb-lg-4" v-html="footer.body">
          </div>
          <div class="pr-lg-5 align-self-center py-2">
            <div class="footer-social-icon-list list-inline">
              <a href="https://www.facebook.com/impianemas.my" class="list-inline-item pr-2" title="Follow Impian Emas on Facebook" target="_blank">
                <div class="icon-yellow-fb footer-social-icon"></div>
              </a>
              <a href="https://www.instagram.com/impianemas.lifecircle/" class="list-inline-item pr-2" title="Follow Impian Emas on Instagram" target="_blank">
                <div class="icon-yellow-instagram footer-social-icon"></div>
              </a>
              <a href="https://api.whatsapp.com/send/?phone=60197337521&text&app_absent=0" class="list-inline-item pr-2" title="Contact us on Whatsapp" target="_blank">
                <div class="icon-yellow-whatsapp footer-social-icon"></div>
              </a>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row class="">
        <b-col md="12" class="footer-copyright text-center px-0 py-2">
          <span>{{ footer.copyright }}</span>
        </b-col>
      </b-row>
    </b-container>
  </footer>
</template>

<script>
import Navbar from '@/components/Navbar'

export default {
  components: {Navbar},
  data () {
    return {
      footer: []
    }
  },
  created () {
    this.$axios
      .get(this.$apiUrl+'/footer?token='+this.$apiToken)
      .then(response => {
        this.footer = response.data.footer;
        // console.log(this.footer);
      }).catch(error => {
        console.log('There was an error:' + error.response)
      })
  }
}

</script>


<style>
  .footer {
    background-color: #fff;
    box-shadow: 0px 40px 60px #999;
  }

  .footer-logo {
	  height: 50px;
    image-rendering: -moz-crisp-edges; /* Firefox */
    image-rendering: -o-crisp-edges; /* Opera */
    image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */    
    margin-bottom: -21px;
  }

  .footer-content {
    font-size: 12px;
  }

  .footer-social-icon {
    color: #fff;
    font-size: 1.75em;
    height: 30px;
    width: 30px;
  }

  .footer-divider {
    border-bottom: 0.5px solid #dee2e666 !important;
  }

  .footer-copyright {
    background-color: #e8a713;
    font-size: 10px;
    color: #fff;
  }

  .footer-social-icon-list {
    min-width: 150px;
    width: 100%;
  }

  .footer .dl {
    font-size: 9px !important;
  }

  @media only screen and (max-width: 768px) {
    .footer {
      font-size: 9pt;
    }

    .footer-logo {
      height: 45px;
      margin-bottom: 0px;
      image-rendering: -moz-crisp-edges; /* Firefox */
      image-rendering: -o-crisp-edges; /* Opera */
      image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
      image-rendering: crisp-edges;
      -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */    
    }

    .footer .column-2 {
      padding-top: 1.24em;
    }

    .footer .column-2, .footer .column-3 {
      border-left: none !important;
    }

    .footer-social-icon-list {
      text-align: center !important;
    }

    .footer-social-icon {
      color: #fff;
      font-size: 1.25em;
      height: 20px;
      width: 20px;
    }


  }

</style>