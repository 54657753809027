<template>
  <b-container fluid="xl" class="py-5">
    <div class="text-center"><h2>{{ section.name }}</h2></div>
    <b-row class="justify-content-center">
      <b-col md="6" class="mt-3" v-for="content in section.contents" :key="content.title" :content="content">
        <b-card
          :title="content.title"
          :img-src="content.image"
          :img-alt="content.title"
          img-top
          tag="article"
          class="card-item-featured mb-2"
        >
          <b-card-text class="text-justify">{{ content.body.substring(0,256)+"..." }}</b-card-text>
          <div class="text-center">
            <b-button :to="content.view_url" variant="primary" class="btn-lg">Read More</b-button>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </b-container>

</template>

<script>
  export default {
    name: 'Products',
    props: {
      section: Object
    },
  }
</script>

<style scoped>

  .card-item-featured {
    border-radius: 0px;
    border: none;
    margin-bottom: 2rem !important;
  }

  .card-item-featured .card-img-top {
    border-radius: 0px;    
    object-fit: cover;
    height: 500px; 
  }

  .card-item-featured .card-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; 
  }

  .card-item-featured .card-text {
    height: 75px;
    overflow: hidden;
    text-overflow: ellipsis; 
  }
</style>