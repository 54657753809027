<template>
    <div>
      <b-card id="card-enquiry-form" class="card-sidebar mb-4 p-3">
          <h5 class="text-center">Loan Payment Calculator</h5>
          
          <div style="height: auto;">
            <form id="form1" action="">
              <table class="table table-sm table-borderless table-loan-calculator" width="235" border="0" cellpadding="2">
                <tbody><tr>
                  <td style="height: 22px">Home Price</td>
                    <td>$</td>
                  <td style="height: 22px">
                    <input name="text" type="text" class="form-control form-control-sm" id="textA" onfocus="this.className='form-control form-control-sm boxFocus'" onkeydown="xdelay('a')" value="750,000" size="15" autocomplete="off">
                  </td>
                </tr>
                <tr>
                  <td>Interest Rate<br>(% per year)</td>
                    <td>
                    </td>
                  <td><input name="text2" type="text" class="form-control form-control-sm" id="textB" onfocus="this.className='form-control form-control-sm boxFocus'" onkeydown="xdelay('b')" value="6" size="5" autocomplete="off"></td>
                </tr>
                <tr>
                  <td>Years</td>
                    <td>
                    </td>
                  <td><input name="text3" type="text" class="form-control form-control-sm" id="textC" onfocus="this.className='form-control form-control-sm boxFocus'" onkeydown="xdelay('c')" value="30" size="5" autocomplete="off"></td>
                </tr>
                <tr>
                  <td>Down Payment</td>
                    <td>
                        $</td>
                  <td><input name="text4" type="text" class="form-control form-control-sm" id="textD" onkeydown="xdelay('d')" value="0" size="15" autocomplete="off"></td>
                </tr>
                <tr>
                  <td>Monthly Payment</td>
                    <td>
                        $</td>
                  <td><input name="text5" type="text" class="form-control form-control-sm" id="resultbox" onkeydown="xdelay('x')" value="4,496.63" size="15"></td>
                </tr>
              </tbody></table>
              <p style="font-size:x-small"><br>Change any combination of fields to calculate the monthly payment. Or change the down payment and monthly payment to calculate the possible home price.</p>
            </form>
					</div>


      </b-card>

      <b-card id="card-enquiry-form" class="card-sidebar mb-4 p-3">
          <h5 class="text-center">Bank Loan</h5>
          <b-row>

            <b-col sm="6 py-2 col-6">
              <a href="https://www.maybank2u.com.my/" target="_blank">
                <img src="../assets/images/banks-logo/maybank2u.png" class="img-fluid bank-logo" alt="maybank2u">
              </a>
            </b-col>

            <b-col sm="6 col-6 py-2">
              <a href="https://www.ambankgroup.com/" target="_blank">
                <img src="../assets/images/banks-logo/ambank.png" class="img-fluid bank-logo" alt="ambank">
              </a>
            </b-col>

            <b-col sm="6 col-6 py-2">
              <a href="https://www.pbebank.com/" target="_blank">
                <img src="../assets/images/banks-logo/publicbank.png" class="img-fluid bank-logo" alt="publicbank">
              </a>
            </b-col>

            <b-col sm="6 col-6 py-2">
              <a href="https://www1.uob.com.my/" target="_blank">
                <img src="../assets/images/banks-logo/uob.png" class="img-fluid bank-logo" alt="uob">
              </a>
            </b-col>

            <b-col sm="6 col-6 col-6 py-2">
              <a href="https://www.affinbank.com.my/" target="_blank">
                <img src="../assets/images/banks-logo/affinbank.png" class="img-fluid bank-logo" alt="affinbank">
              </a>
            </b-col>

            <b-col sm="6 col-6 py-2">
              <a href="https://www.hsbc.com.my/" target="_blank">
                <img src="../assets/images/banks-logo/hsbc.png" class="img-fluid bank-logo" alt="hsbc">
              </a>
            </b-col>

            <b-col sm="6 col-6 py-2">
              <a href="https://www.hlb.com.my/" target="_blank">
                <img src="../assets/images/banks-logo/hongleong.png" class="img-fluid bank-logo" alt="hongleong">
              </a>
            </b-col>

            <b-col sm="6 col-6 py-2">
              <a href="https://www.citibank.com.my/" target="_blank">
                <img src="../assets/images/banks-logo/citybank.png" class="img-fluid bank-logo" alt="citybank">
              </a>
            </b-col>

            <b-col sm="6 col-6 py-2">
              <a href="https://www.cimbbank.com.my/" target="_blank">
                <img src="../assets/images/banks-logo/cimb.png" class="img-fluid bank-logo" alt="cimb">
              </a>
            </b-col>

            <b-col sm="6 col-6 py-2">
              <a href="https://www.rhbgroup.com/" target="_blank">
                <img src="../assets/images/banks-logo/rhb.png" class="img-fluid bank-logo" alt="rhb">
              </a>
            </b-col>

            <b-col sm="6 col-6 py-2">
              <a href="https://www.ocbc.com.my/" target="_blank">
                <img src="../assets/images/banks-logo/ocbc.png" class="img-fluid bank-logo" alt="ocbc">
              </a>
            </b-col>

            <b-col sm="6 col-6 py-2">
              <a href="https://www.mbsb.com.my/" target="_blank">
                <img src="../assets/images/banks-logo/mbsb.png" class="img-fluid bank-logo" alt="mbsb">
              </a>
            </b-col>

          </b-row>
      </b-card>

      <b-card id="card-enquiry-form" class="card-sidebar mb-4 p-3">
          <h5 class="text-center">EPF Information</h5>
          <div class="d-flex align-items-center justify-content-center">
            <a href="http://www.kwsp.gov.my/" target="_blank">
              <img src="../assets/images/epf.gif" alt="" class="img-fluid">
            </a>
          </div>
      </b-card>
    </div>
</template>

<script>


export default{
  mounted() {

  }
}
</script>


<style scoped>
  .card-sidebar {
    font-family: 'Trebuchet MS', sans-serif;
    border-radius: 12px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    color: #555 !important;
  }

  .bank-logo {
    object-fit: cover;
    image-rendering: -moz-crisp-edges; /* Firefox */
    image-rendering: -o-crisp-edges; /* Opera */
    image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
  }

  .table-loan-calculator {
    color: #555 !important;
    font-size: 0.9em;
  }
</style>