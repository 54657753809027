<template>
  <div>

    <b-row>
      <b-col md="12" class="d-flex justify-content-end">
        <div>
          <b-dropdown id="dropdown-year" :text="selectedYear" class="m-md-2 bg-white text-yellow">
            <b-dropdown-item v-for="year in years" :key="year" @click="filterByYear(year)">{{ year }}</b-dropdown-item>
          </b-dropdown>
        </div>
      </b-col>
    </b-row>

    <b-row>
      <div class="col-6 col-md-4 col-lg-3 px-1 px-lg-2" v-for="article in articles" :key="article.id">
        <a href="javascript:void(0)" class="card-link" @click="showArticleViewModal(article.view_url)">
          <b-card
            :title="article.title"
            :img-src="article.image"
            :img-alt="article.title"
            img-top
            class="card-article mb-1 mb-lg-3"
          >
            <div class="card-overlay">
              <button class="btn btn-yellow">Read More</button>
            </div>
          </b-card>
        </a>
      </div>
    </b-row>
    <div class="overflow-auto mt-3 mb-5">
      <b-pagination
        v-model="currentPage"
        :total-rows="paginator.count"
        :per-page="paginator.perPage"
        first-text="First"
        prev-text="Prev"
        next-text="Next"
        last-text="Last"
        align="center"
      ></b-pagination>
    </div>

    <b-modal ref="article-view-modal" id="article-view-modal" size="xl" centered hide-footer scrollable>
      <div class="article-content">
        <div class="text-center">
          <div v-if="article.video_url !== null" class="embed-responsive embed-responsive-16by9">
           <iframe 
              class="embed-responsive-item"
              :src="article.video_url" 
              style="border:none;" 
              scrolling="no"
              allowTransparency="true"
              frameborder="0" 
              allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" 
              allowFullScreen="true">
            </iframe>
          </div>
          <div v-else>
            <img :src="article.image" alt="" class="img-fluid">
          </div>
        </div>
        <h3 class="font-weight-bold pt-5 pb-3">{{ article.title }}</h3>
        <div v-html="article.body" class="mb-4"></div>
      </div>
    </b-modal>
  </div>
</template>

<script>

  export default {
    name: 'Articles',
    props: {
      section: Object
    },
    components: {
    },
    data () {
      return {
        articles: {},
        article: {},
        paginator: {},
        slug: '',
        currentPage: typeof this.$route.query.page !== 'undefined' ? this.$route.query.page: 1,
        selectedYear: 'Year',
        years: ['Year', '2022', '2021', '2020', '2019', '2018', '2017', '2016', '2015'],
      }
    },
    computed: {
      pageName() {
          return this.$route.params.page
      }
    },
    created () {
      // console.log(this.section);
      // this.$axios
      //     .get(this.$apiUrl+this.section.view_url+'?page_limit=8&token='+this.$apiToken)
      //     .then(response => {
      //       this.articles = response.data.articles;
      //       this.paginator = response.data.paginator;
      //       // console.log(this.pageData);
      //     }).catch(error => {
      //       console.log('There was an error:' + error.response)
      //     })
    },
    methods: {
      filterByYear(year) {
        this.selectedYear = year;

        let url;
        if (this.selectedYear !== 'Year') {
          url = this.$apiUrl+this.section.view_url+'?page_limit=8&year='+this.selectedYear+'&token='+this.$apiToken;
        } else {
          url = this.$apiUrl+this.section.view_url+'?page_limit=8&token='+this.$apiToken;
        }  

        this.$axios
          .get(url)
          .then(response => {
            this.articles = response.data.articles;
            this.paginator = response.data.paginator;
            this.currentPage = this.paginator.page;

            if (this.selectedYear !== 'Year') {
              var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?page=' + this.currentPage + '&year=' + this.selectedYear;
              window.history.pushState({path:newurl},'',newurl);
            } else {
              var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?page=' + this.currentPage;
              window.history.pushState({path:newurl},'',newurl);
            }

            document.body.scrollTop = 0; // For Safari
            document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
            // console.log(this.pageData);
          }).catch(error => {
            console.log('There was an error:' + error.response)
          })

      },
      showArticleViewModal(viewUrl) {
        this.$axios
          .get(this.$apiUrl+viewUrl+'?token='+this.$apiToken)
          .then(response => {
            this.article = response.data.article;
            this.$refs['article-view-modal'].show()

            // set query param when article modal opened
            var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?title=' + this.article.slug;
            window.history.pushState({path:newurl},'',newurl);
          }).catch(error => {
            console.log('There was an error:' + error.response)
          })
      },
      async fetchData() {
        // if (typeof this.$route.query.page !== 'undefined') {
        //   this.currentPage = this.$route.query.page);
        // } 
        // console.log(this.currentPage);
        let url;

        if (this.selectedYear !== 'Year') {
          url = this.$apiUrl+this.section.view_url+'?page_limit=8&page='+this.currentPage+'&year='+this.selectedYear+'&token='+this.$apiToken;
        } else {
          url = this.$apiUrl+this.section.view_url+'?page_limit=8&page='+this.currentPage+'&token='+this.$apiToken;
        }  


        this.$axios
          .get(url)
          .then(response => {
            this.articles = response.data.articles;
            this.paginator = response.data.paginator;
            // this.currentPage = this.paginator.page;

            if (this.selectedYear !== 'Year') {
              var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?page=' + this.currentPage + '&year=' + this.selectedYear;
              window.history.pushState({path:newurl},'',newurl);
            } else {
              var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?page=' + this.currentPage;
              window.history.pushState({path:newurl},'',newurl);
            }

            document.body.scrollTop = 0; // For Safari
            document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
            // console.log(this.pageData);
          }).catch(error => {
            console.log('There was an error:' + error.response)
          })
        // this.items = await fetch(`https://jsonplaceholder.typicode.com/comments?_page=${this.currentPage}&_limit=${this.perPage}`)
        //   .then(res => {
        //     this.totalItems = parseInt(res.headers.get('x-total-count'), 10)

        //     return res.json()
        //   })
        //   .then(items => items)
      }
    },
    mounted () {
      

      this.fetchData().catch(error => {
        console.error(error)
      })

      if (typeof this.$route.query.title !== 'undefined') {
        this.$axios
          .get(this.$apiUrl+'/articles/'+this.$route.query.title+'?token='+this.$apiToken)
          .then(response => {
            this.article = response.data.article;
            this.$refs['article-view-modal'].show()
          }).catch(error => {
            console.log('There was an error:' + error.response)
          })
      } 
      // console.log(this.$route.query.title)

      this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
        if (modalId == 'article-view-modal') {
          if (this.selectedYear !== 'Year') {
            var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?page=' + this.currentPage + '&year=' + this.selectedYear;
            window.history.pushState({path:newurl},'',newurl);
          } else {
            var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?page=' + this.currentPage;
            window.history.pushState({path:newurl},'',newurl);
          }
        }
      })
    },
    watch: {
      currentPage: {
        handler: function(value) {
          this.fetchData().catch(error => {
            console.error(error)
          })
        }
      }
    }

  }
</script>


<style>

  .card-link {
    color: #212529;
  }

  .card-link:hover .card-title{
    text-decoration: none;
  }

  .card-article {
    border-radius: 12px !important;
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  }

  .card-article .card-img-top {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    object-fit: cover;
    height: 225px; 
  }

  .card-article .card-body {
    padding: 15px 15px 15px 15px;
    height: 75px;
  }

  .card-article .card-title {
    margin-bottom: 0px;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    font-size: 1.25em;
  }

  .card-article .card-text {

  }

  .card-overlay {
    display: none;
    position:absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    -moz-transform: translate(-50%,-50%);
    width: 100%;
    height: 100%;
    background: rgb(58 58 58 / 45%);
    margin: auto;
    text-align: center;
  }

  .card-link:hover .card-overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
  }

  .btn-yellow {
    color: #fff;
    background-color: #e8a713;
    border-color: #e8a713;
    border-radius: 10px;
  }

  .card-link:hover .btn-yellow {
    color: #fff;
  }

  .article-content {
    padding-left: 100px !important;
    padding-right: 100px !important;
  }

  #dropdown-year .btn{
    color:#e8a713 !important;
    background: #fdfdfd;
    border: none;
    font-size: 1.15em;
    font-weight: bold;
  }

  .btn-secondary:focus, .btn.focus {
    box-shadow: none !important;
  }


  #dropdown-year .dropdown-menu {
    min-width: 5.5rem;
    border-radius: 0px;
  }

  #dropdown-year .dropdown-item {
    color:#e8a713;
  }


  @media only screen and (max-width: 768px) {
    #dropdown-year .btn{
      font-size: .9em;
      font-weight: bold;
    }

    #dropdown-year .dropdown-item {
      color:#e8a713;
      font-size: .9em;
    }

    .card-article .card-img-top {
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
        -o-object-fit: cover;
        object-fit: cover;
        height: 135px;
    }

    .card-article .card-title {
        font-size: 1em;
    }

    .card-article .card-body {
        padding: 7px 5px 5px 5px;
        height: 53px;
    }

    .article-content {
      padding-left: 0px !important;
      padding-right: 0px !important;
    }


  }

  /* iPads (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    .article-content {
      padding-left: 0px !important;
      padding-right: 0px !important;
    }
  }



</style>