<template>
  <section>
    

    <div v-if="currentRouteName == 'Home'">
      <EmasCoverImage/> 
    </div>


    <div v-if="section.section_type.code == 'Carousel'">
      <Carousel :contents="section.contents"/>  
    </div>
    <div v-else-if="section.section_type.code == 'Article'">
      <div class="container">
        <b-row>
          <div class="col-md-12" v-for="content in section.contents" :key="content.title">
            <Article :content="content"/>  
          </div>
        </b-row>
      </div>
    </div>
    <div v-else-if="section.section_type.code == 'CallToAction'">
      <CallToAction v-for="content in section.contents" :key="content.title" :content="content"/>  
    </div>
    <div v-else-if="section.section_type.code == 'ItemFeatured'">
      <ItemFeatured :section="section"/>  
    </div>
    <div v-else-if="section.section_type.code == 'ItemList'">
      <b-container fluid="xl" v-if="section.view_url == '/products'">
        <ProductList :section="section"/>  
      </b-container>
      <b-container fluid="xl" v-else-if="section.view_url == '/articles'">
        <ArticleList :section="section"/>  
      </b-container>
      <b-container fluid="xl" v-else-if="section.view_url == '/brands'">
        <BrandList :section="section"/>  
      </b-container>
    </div>
    <div v-else-if="section.section_type.code == 'ContactUs'">
      <ContactInfo />  
    </div>
    <div v-else-if="section.section_type.code == 'ContactForm'">
      <ContactForm />  
    </div>
    <div v-else-if="section.section_type.code == 'GoogleMap'">
      <GoogleMap :section="section"/>  
    </div>
    <div v-else>
    </div>
    <!-- <div class="text-center">{{ section.name }}</div> -->
  </section>
</template>

<script>

// @ is an alias to /src
import Carousel from '@/components/Carousel.vue'
import CallToAction from '@/components/CallToAction.vue'
import Article from '@/components/Article.vue'
import ArticleList from '@/components/ArticleList.vue'
import BrandList from '@/components/BrandList.vue'
import ItemFeatured from '@/components/ItemFeatured.vue'
import ProductList from '@/components/ProductList.vue'
import ContactInfo from '@/components/ContactInfo.vue'
import ContactForm from '@/components/ContactForm.vue'
import GoogleMap from '@/components/GoogleMap.vue'
import EmasCoverImage from '@/components/EmasCoverImage.vue'

export default {
  name: 'Section',
  props: {
    section: Object
  },
  computed: {
    currentRouteName() {
        return this.$route.name;
    }
  },
  components: {
    Carousel,
    CallToAction,
    Article,
    ArticleList,
    BrandList,
    ItemFeatured,
    ContactInfo,
    ContactForm,
    ProductList,
    GoogleMap,
    EmasCoverImage,
  }
}
</script>
