<template>
    <div>
      <div class="emas-cover-image d-none">
      <!-- <div class="emas-cover-image d-none d-sm-block"> -->
        <div class="hotspot hs-1">
          <div class="hotspot-content-wrapper">
            <div class="hotspot-360-link d-flex pl-3">
              <a href="https://360vr.my/tamanimpianemas/" target="_blank">
                <div class="icon-yellow-360 hotspot-icon"></div>
              </a>
            </div>
            <div class="hotspot-content">
              <div class="hotspot-body px-3">
              <h6>Impian Square</h6>
              <p>The completed lifestyle and recreational belt of Taman Impian Emas features a promenade of shops, cafés and restaurants. The inner stretch of the precinct also offers facilities for an active and healthy lifestyle.</p>
              </div>
            </div>
          </div>
        </div>
        <div class="hotspot hs-2">
          <div class="hotspot-content-wrapper">
            <div class="hotspot-360-link d-flex pl-3">
              <a href="https://360vr.my/tamanimpianemas/" target="_blank">
                <div class="icon-yellow-360 hotspot-icon"></div>
              </a>
            </div>
            <div class="hotspot-content">
              <div class="hotspot-body px-3">
              <h6>Daisy Hill Recreation Park</h6>
              <p>Another slice of leisure, residents and visitors can enjoy a stretch of shops and cafes that spill into the greenery of the nearby recreational park. Accessible from multiple pedestrian pathways, the park includes a jogging track and cycling path.</p>
              </div>
            </div>
          </div>
        </div>
        <div class="hotspot hs-3">
          <div class="hotspot-content-wrapper">
            <div class="hotspot-360-link d-flex pl-3">
              <a href="https://360vr.my/tamanimpianemas/" target="_blank">
                <div class="icon-yellow-360 hotspot-icon"></div>
              </a>
            </div>
            <div class="hotspot-content">
              <div class="hotspot-body px-3">
              <h6>Impian Heights</h6>
              <p>Also completed, stop for a bite at Impian Heights, where the many restaurants will cater to any craving worked up at the nearby Impian Emas Golf Course.</p>
              </div>
            </div>
          </div>
        </div>
        <div class="hotspot hs-4">
          <div class="hotspot-content-wrapper">
            <div class="hotspot-360-link d-flex pl-3">
              <a href="https://360vr.my/tamanimpianemas/" target="_blank">
                <div class="icon-yellow-360 hotspot-icon"></div>
              </a>
            </div>
            <div class="hotspot-content">
              <div class="hotspot-body px-3">
              <h6>Marigold Spring</h6>
              <p>An urban blend of commercial lots, entertainment outlets and SoHo offices, Marigold Springs is a vibrant precinct with wide, pedestrian-friendly sidewalks giving it a walkable atmosphere.</p>
              </div>
            </div>
          </div>
        </div>
        <div class="hotspot hs-5">
          <div class="hotspot-content-wrapper">
            <div class="hotspot-360-link d-flex pl-3">
              <a href="https://360vr.my/tamanimpianemas/" target="_blank">
                <div class="icon-yellow-360 hotspot-icon"></div>
              </a>
            </div>
            <div class="hotspot-content">
              <div class="hotspot-body px-3">
              <h6>Richfields Plaza & Community Hub</h6>
              <p>Centrally located, this exciting community hub hosts a futsal & badminton court and an Olympic-sized swimming pool. Linked by a bridge is the plaza, where you can pop into a café or the supermarket.</p>
              </div>
            </div>
          </div>
        </div>
        <div class="hotspot hs-6">
          <div class="hotspot-content-wrapper">
            <div class="hotspot-360-link d-flex pl-3">
              <a href="https://360vr.my/tamanimpianemas/" target="_blank">
                <div class="icon-yellow-360 hotspot-icon"></div>
              </a>
            </div>
            <div class="hotspot-content">
              <div class="hotspot-body px-3">
              <h6>Bukit Impian Residence</h6>
              <p>Tucked behind the Impian Emas Golf Course, the partially developed Bukit Impian Residence features spacious modern semi-D homes, strategically located for easy access to all the best activities within the area.</p>
              </div>
            </div>
          </div>
        </div>
        <div class="hotspot hs-7">
          <div class="hotspot-content-wrapper">
            <div class="hotspot-360-link d-flex pl-3">
              <a href="https://360vr.my/tamanimpianemas/" target="_blank">
                <div class="icon-yellow-360 hotspot-icon"></div>
              </a>
            </div>
            <div class="hotspot-content">
              <div class="hotspot-body px-3">
              <h6>Impian Emas Golf Course</h6>
              <p>The green heart of Taman Impian Emas, the Impian Emas Golf Course offers a sweet 9-hole golf course within great reach. With gently rolling hills and a manicured path lined with shady oaks, for idyllic afternoons on the course.</p>
              </div>
            </div>
          </div>
        </div>
        <div class="hotspot hs-8">
          <div class="hotspot-content-wrapper">
            <div class="hotspot-360-link d-flex pl-3">
              <a href="https://360vr.my/tamanimpianemas/" target="_blank">
                <div class="icon-yellow-360 hotspot-icon"></div>
              </a>
            </div>
            <div class="hotspot-content">
              <div class="hotspot-body px-3">
              <h6>Primrose Place</h6>
              <p>A precinct of spacious super-linked homes for multigenerational living, the residences here enjoy a sprawling view of rolling greens, as they are just a stone’s throw away from the Impian Emas Golf Course.</p>
              </div>
            </div>
          </div>
        </div>
        <div class="hotspot hs-9">
          <div class="hotspot-content-wrapper">
            <div class="hotspot-360-link d-flex pl-3">
              <a href="https://360vr.my/tamanimpianemas/" target="_blank">
                <div class="icon-yellow-360 hotspot-icon"></div>
              </a>
            </div>
            <div class="hotspot-content">
              <div class="hotspot-body px-3">
              <h6>Honey Dale Residence</h6>
              <p>Curved to the natural landscape, Honeydale Residence features an array of homes, from exclusive hilltop bungalows for the more mature, a cluster home range by the lake for growing families, and contemporary apartments for young adults.</p>
              </div>
            </div>
          </div>
        </div>
        <div class="hotspot hs-10">
          <div class="hotspot-content-wrapper">
            <div class="hotspot-360-link d-flex pl-3">
              <a href="https://360vr.my/tamanimpianemas/" target="_blank">
                <div class="icon-yellow-360 hotspot-icon"></div>
              </a>
            </div>
            <div class="hotspot-content">
              <div class="hotspot-body px-3">
              <h6>Iconia Residence</h6>
              <p>Taking on a more tropical climate at Iconia Garden Residence, the gated community features wide green spaces that trail from its signature entrance to the pedestrian links, along terrace and premium super-linked homes for multigenerational living.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-dragscroll.pass id="desktop-cover" class="emas-cover-image-desktop d-none d-sm-block">
        <iframe id="iframe_d_cover" @load="iframeScroll()" align="middle" src="/emascoverimage.html" allowtransparency="false" frameborder="0" scrolling="yes"></iframe>
        <div class="desktop-cover-overlay">
          <div class="icon-swiper-white"></div>
        </div>
      </div>
         
      <div id="mobile-cover" class="emas-cover-image-mobile d-block d-sm-none">
        <div class="mobile-cover-overlay">
          <div class="icon-swiper-white"></div>
        </div>
        <iframe id="iframe_m_cover" @load="iframeScroll()" align="middle" src="/emascoverimage.html" allowtransparency="true" frameborder="0" scrolling="no"></iframe>
      </div>
    </div>
</template>

<script>


export default {
  name: 'EmasCoverImage',
  created() {

  },
  mounted() {
      console.log('exec');
      const content = document.querySelector('#mobile-cover');
      content.scrollLeft += 1700/2;

      const content1 = document.querySelector('#desktop-cover');
      content1.scrollLeft += 180;
      // event.preventDefault();

      // $('#iframe_d_cover').draggable({ iframeFix: true });
      // const slider = document.querySelector('.emas-cover-image-desktop');
      // let mouseDown = false;
      // let startX, scrollLeft;

      // let startDragging = function (e) {
      //   mouseDown = true;
      //   startX = e.pageX - slider.offsetLeft;
      //   scrollLeft = slider.scrollLeft;
      // };
      // let stopDragging = function (event) {
      //   mouseDown = false;
      // };

      // slider.addEventListener('mousemove', (e) => {
      //   e.preventDefault();
      //   if(!mouseDown) { return; }
      //   const x = e.pageX - slider.offsetLeft;
      //   const scroll = x - startX;
      //   slider.scrollLeft = scrollLeft - scroll;
      // });

      // // Add the event listeners
      // slider.addEventListener('mousedown', startDragging, false);
      // slider.addEventListener('mouseup', stopDragging, false);
      // slider.addEventListener('mouseleave', stopDragging, false);
  },
  methods: {
    iframeScroll() {

    }
  }
}

</script>

<style scoped>
  .emas-cover-image {
    background-image: url('../assets/images/bg_arial_view.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    width: 100%;
    height: 82vh !important;
  }

  .hotspot {
    /* border: 2px solid rgb(217, 255, 0); */
    position: absolute;
    height: 60px;
    width: 43px;
  }

  .hotspot .btn, .hotspot .btn:focus, .hotspot .btn:active, .hotspot .btn:not(:disabled):not(.disabled):active {
    width: 100%;
    height: 100%;
    background: #fff0;
    border: none;
    box-shadow: none;
  }

  .hs-1 {
    bottom: 27.3%;
    right: 15.7%;
  }

  .hs-2 {
    bottom: 16.3%;
    left: 35.5%;
  }

  .hs-3 {
    bottom: 37%;
    right: 31%;
  }

  .hs-4 {
    top: 47.1%;
    right: 29.3%;
  }

  .hs-5 {
    top: 45.2%;
    left: 21%;
  }

  .hs-6 {
    left: 50.8%;
    top: 40.4%;
  }

  .hs-7 {
    bottom: 37.4%;
    left: 37%;  
  }

  .hs-8 {
    right: 14.4%;
    top: 49.4%;   
  }

  .hs-9 {
    left: 53.4%;
    top: 34.5%;
  }

  .hs-10 {
    top: 37.7%;
    left: 18.5%;
  }

  @media (max-width: 1300px) { 
    .hs-1 {
      bottom: 23.3%;
      right: 8.7%;
    }

    .hs-2 {
      bottom: 12.3%;
      left: 32.5%;
    }

    .hs-3 {
      bottom: 33%;
      right: 27%;
    }

    .hs-4 {
      top: 50.1%;
      right: 25.3%;
    }

    .hs-5 {
      top: 48.2%;
      left: 15.6%;
    }

    .hs-6 {
      left: 50.1%;
      top: 43.4%;
    }

    .hs-7 {
      bottom: 33.4%;
      left: 34%;
    }

    .hs-8 {
      right: 7.4%;
      top: 52.4%;
    }

    .hs-9 {
      left: 53.4%;
      top: 37.5%;
    }

    .hs-10 {
      top: 40.7%;
      left: 12.5%;
    }
  }

  .hotspot-content-wrapper {
    display: none !important;
    width: 270px;
    height: 170px;
  }

  .hotspot-360-link {
    position: absolute;
    top: 0px;
    margin-top: -205px;
    margin-left: -31px;
  }

  .hotspot-content {
      width: 270px;
      height: 159px;
      overflow: hidden;
      /* position: absolute; */
      padding-top: 10px;
      margin-top: -158px;
      margin-left: 18px;
      /* background: rgba(255, 255, 255, 0.75); */
      border-left: 1px solid #e8a713;
      /* border-radius: 12px; */
  }

  .hotspot-content .hotspot-body {
    border-radius: 5px;
    background: #ffffffd7 !important;
  }


  .hotspot-content .hotspot-desc {
    padding-left: 2rem;
  }

  .hotspot-content h6 {
    font-size: 1.25em;
    color: #e8a713;
    text-shadow: 0.5px 0.5px #ffffffe0 !important;
    font-weight: bold
  }

  .hotspot-content p {
    font-weight: 400;
    font-size: 12px;
    text-shadow: 0.5px 0.5px #ffffffe0 !important;
    text-align: justify;
  }

  .hotspot-icon {
    color: #fff;
    font-size: 1.75em;
    height: 64px;
    width: 64px;
  }

  .hotspot:hover .hotspot-content-wrapper {
    display: block !important;
    /* display: flex !important;   
    webkit-box-orient: vertical!important;
    -webkit-box-direction: normal!important;
    -ms-flex-direction: column!important;
    flex-direction: column!important; */
  }

  @media (min-resolution: 120dpi) {
    .hotspot-content {
        width: 270px;
        height: 130px;
        overflow: hidden;
        /* position: absolute; */
        padding-top: 10px;
        margin-top: -102px;
        margin-left: 18px;
        /* background: rgba(255, 255, 255, 0.75); */
        border-left: 1px solid #e8a713;
        /* border-radius: 12px; */
    }

    .hotspot-content p {
      font-size: 7.5pt !important;
    }

    .hotspot-icon {
      color: #fff;
      font-size: 16pt;
      height: 54px;
      width: 54px;
    }

    .hotspot-content h6 {
      font-size: 12pt !important;
    }

    .hotspot-360-link {
        margin-top: -142px;
        margin-left: -27px;
    }

  }

  .emas-cover-image-desktop{
    overflow: auto;
    height: 80vh;
    width: 100%;
    cursor: -webkit-grabbing; 
    cursor: grabbing;
    overflow: hidden;
  }

  .emas-cover-image-mobile{
    overflow: auto;
    height: 80vh;
  }

  .emas-cover-image-mobile::-webkit-scrollbar { display: none; }

  .emas-cover-image-desktop iframe, .emas-cover-image-mobile iframe {
    display: block;
    width: 1920px;
    height: 796px;
    margin: 0 auto;
    text-align: center;
  }

  .emas-cover-image-desktop iframe, .emas-cover-image-mobile iframe {
    z-index: 99999;
  }


  @media (min-resolution: 120dpi) {
    .emas-cover-image-desktop iframe {
      /* -ms-zoom: 0.8;
      -moz-transform: scale(0.8);
      -moz-transform-origin: 0 0;
      -o-transform: scale(0.8);
      -o-transform-origin: 0 0;
      -webkit-transform: scale(0.8);
      -webkit-transform-origin: 0 0; */
    }

  }


  .desktop-cover-overlay {
    position: absolute;
    display: flex;
    justify-content: center;
    /* z-index: 1000; */
    bottom: 10%;
    width: 100%;
  }


  .mobile-cover-overlay {
    position: absolute;
    display: flex;
    justify-content: center;
    z-index: 1000;
    top: 78%;
    width: 100%;
  }

  /* .emas-cover-image-mobile:hover .mobile-cover-overlay {
    display: flex;
    justify-content: center;
  } */


  .icon-swiper-white {
    width: 75px;
    height: 75px;
    background-image: url('../assets/images/icon-swipe-white.png');
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
    image-rendering: -webkit-optimize-contrast;
  }

  .desktop-cover-overlay .icon-swiper-white {
    width: 90px;
    height: 90px;
  }


  /* .emas-cover-image-mobile iframe  {
    zoom: 0.86;
    -moz-transform: scale(0.85);
    -moz-transform-origin: 0 0;
    -o-transform: scale(0.85);
    -o-transform-origin: 0 0;
    -webkit-transform: scale(0.85);
    -webkit-transform-origin: 0 0;
  } */


</style>
