<template>
    <div v-html="section.contents" class="py-3"></div>
</template>

<script>
  export default {
    name: 'GoogleMap',
    props: {
      section: Object
    },
  }
</script>