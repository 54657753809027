<template>
  <div class="home">
    
    <b-modal v-if="videoIntro" ref="video-intro-modal" id="video-intro-modal" hide-footer hide-header>
      <b-row class="justify-content-center h-100">
        <b-col md="9" class="d-flex align-items-center">
          <div class="embed-responsive embed-responsive-16by9">
            <iframe 
            class="embed-responsive-item"
              width="100%" 
              height="100%"
              src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2Fimpianemas.my%2Fvideos%2F1572913826420025%2F&show_text=false&autoplay=false&control=false" 
              style="border:none;" 
              scrolling="no"
              allowTransparency="true"
              frameborder="0" 
              allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" 
              allowFullScreen="true">
            </iframe>
            <div class="modal-overlay">
              <a href="javascript:void(0)" @click="closeVideoIntroModal()">Skip&nbsp;<b-icon icon="chevron-right"></b-icon></a>
            </div>
          </div>
        </b-col>  
      </b-row> 
    </b-modal>

    <div v-if="videoIntro" class="video-intro-mobile d-block d-md-none">
      <div class="d-flex justify-content-center align-items-center h-100">
        <div class="embed-responsive embed-responsive-16by9">
          <iframe 
            class="embed-responsive-item"
            width="560" 
            height="315"
            src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2Fimpianemas.my%2Fvideos%2F1572913826420025%2F&show_text=false&autoplay=false&control=false&allowfullscreen=false" 
            style="border:none;" 
            scrolling="no"
            allowTransparency="true"
            frameborder="0" 
            allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" 
            allowFullScreen="true">
          </iframe>
          <div class="video-intro-mobile-overlay">
            <a href="javascript:void(0)" @click="closeVideoIntro()">Skip&nbsp;<b-icon icon="chevron-right"></b-icon></a>
          </div>
        </div>
      </div>
    </div>

    <div v-if="videoIntro == false">
      <EmasLoyaltyProgram/>
      <EmasCoverImage/>

      <div class="d-block d-md-none">
        <div class="pt-5 px-4">
          <h1 class="page-title text-center">News & Events</h1>
          <b-row>
            <div class="col-6 col-md-4 col-lg-3 px-1 px-lg-2" v-for="article in articles" :key="article.id">
              <router-link :to="'news-events?title='+article.slug" class="card-link">
                <b-card
                  :title="article.title"
                  :img-src="article.image"
                  :img-alt="article.title"
                  img-top
                  class="card-article mb-1 mb-lg-3"
                >
                  <div class="card-overlay">
                    <button class="btn btn-yellow">Read More</button>
                  </div>
                </b-card>
              </router-link>
            </div>
          </b-row>
        </div>

        <div class="pt-5 px-4">
          <h1 class="page-title text-center">Projects</h1>
          <div>
            <router-link :to="project1.view_url" class="card-link">
              <b-card
                overlay
                :img-src="project1.default_product_image.image_url"
                class="card-project-home mb-3"
              >
                <div class="d-flex align-items-end h-100">
                  <h4>New Launch</h4>
                </div>
              </b-card> 
            </router-link>
          </div>

          <div>
            <router-link :to="project2.view_url" class="card-link">
              <b-card
                overlay
                :img-src="project2.default_product_image.image_url"
                class="card-project-home mb-3"
              >
                <div class="d-flex align-items-end h-100">
                  <h4>Ready To Move In</h4>
                </div>
              </b-card> 
            </router-link>
          </div>
        </div>

        <div class="pt-2 px-4">
          <FacilitiesAmenities :pageData="pageData"/>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
// @ is an alias to /src
import Carousel from '@/components/Carousel.vue'
import CallToAction from '@/components/CallToAction.vue'
import Article from '@/components/Article.vue'
import ItemFeatured from '@/components/ItemFeatured.vue'
import EmasCoverImage from '@/components/EmasCoverImage.vue'
import EmasLoyaltyProgram from '@/components/EmasLoyaltyProgram.vue'
// import VideoIntro from '@/components/VideoIntro.vue'
import FacilitiesAmenities from '@/views/FacilitiesAmenities.vue'

export default {
  name: 'Home',  
  props: {
    pageData: Object
  },
  components: {
    Carousel,
    CallToAction,
    Article,
    ItemFeatured,
    EmasCoverImage,
    EmasLoyaltyProgram,
    FacilitiesAmenities,
    // VideoIntro,
  },
  data () {
    return {
      videoIntro: true,
      articles: {},
      project1: {},
      project2: {},
    }
  },
  mounted() {
    const isMobile = window.matchMedia("only screen and (max-width: 760px)").matches;
    if (!isMobile) {
        this.$refs['video-intro-modal'].show()
    }
  },
  beforeCreate () {
    // console.log(this.section);
    this.$axios
        .get(this.$apiUrl+'/articles?page_limit=2&token='+this.$apiToken)
        .then(response => {
          this.articles = response.data.articles;
          // console.log(this.pageData);
        }).catch(error => {
          // console.log('There was an error:' + error.response)
        })

    // get the latest new launch project
    this.$axios
        .get(this.$apiUrl+'/products/search?page_limit=1&keywords=New%20Launch&token='+this.$apiToken)
        .then(response => {
          this.project1 = response.data.products[0];
          // console.log(this.pageData);
        }).catch(error => {
          // console.log('There was an error:' + error.response)
        })
    // get the latest ready to move in project
    this.$axios
        .get(this.$apiUrl+'/products/search?page_limit=1&keywords=Ready%20To%20Move%20In&token='+this.$apiToken)
        .then(response => {
          this.project2 = response.data.products[0];
          // console.log(this.pageData);
        }).catch(error => {
          // console.log('There was an error:' + error.response)
        })
  },
  methods: {
    closeVideoIntroModal() {
      this.videoIntro = false;
      this.$refs['video-intro-modal'].hide()
    },
    closeVideoIntro() {
      this.videoIntro = false;
    }
  }  
}
</script>

<style>
#video-intro-modal___BV_modal_outer_ {
  z-index: 99999 !important;
}

#video-intro-modal {
  padding: 0px !important;
}
/* 
#video-intro-modal .modal-dialog {
    width: 100%;
    max-width: 100% !important;
    height: 100%;
    margin: 0;
}

#video-intro-modal .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
}*/

#video-intro-modal .modal-body {
  margin: 0;
  padding: 0;
  background-color: #000;
} 

#video-intro-modal .embed-responsive-16by9::before {
  padding-top: 62.9%;
}

.video-intro-mobile {
  background: #222;
  height: 75vh;
}

.video-intro-mobile-overlay {
  padding-top: 5px;
  position: relative;
  text-align: right;
  width: 100%;
}

.video-intro-mobile-overlay a {
  color: #fff;
  text-decoration: none;
}

.facebook-responsive {
    /* overflow:hidden;
    padding-bottom:56.25%;
    position:relative;
    height:0; */
  position: relative;
  overflow: hidden;
  width: 100vw;
  height: 100vh;

}

.facebook-responsive iframe {
    /* left:0;
    top:0;
    height:100%;
    width:100%;
    position:absolute; */
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100vw;
    height: 90vh;
    transform: translate(-50%, -50%);
    object-fit: cover !important;
}

.facebook-responsive video {
  object-fit: cover !important;
}

@media screen and (max-width:640px) {
  div#block-yui_3_17_2_1_1596082723574_4344 iframe {
      height: auto;
  }
}

@media only screen and (max-width: 767.98px) {
  #video-intro-modal .modal-body {
    background-color: #222;
  }
}

@media (min-width: 576px) {
  #video-intro-modal .modal-dialog {
      width: 100% !important;
      max-width: 100% !important;
      height: 100% !important;
      margin: 0;
  }
}

#video-intro-modal .modal-overlay {
  position: absolute;
  bottom: 50px;
  right: 5px;
  /* float: right; */
  /* text-align: right;
  width: 100%; */
}
#video-intro-modal .modal-overlay a {
  color: #fff;
  font-size: 28px;
}

.card-project-home {
  border-radius: 9px !important;
}

.card-project-home .card-img, .card-project-home .card-img-bottom {
    border-radius: 7px;
    height: 175px;
    object-fit: cover;
    object-position: center;
}

.card-project-home .card-body {
     border-radius: 7px;
    /* background: #555; */
    padding: 1rem 1rem 0rem 1rem;
    height: 175px;
    color: #fff !important;
}

@media screen and (orientation:landscape) and
(min-device-width: 320px) and (max-device-width: 450px) {
   .video-intro-mobile {
     display: block !important;
   }
}

/* @media (min-aspect-ratio: 16/9) {
  .facebook-responsive iframe {
    height: 56.25vw;
  }
}
@media (max-aspect-ratio: 16/9) {
  .facebook-responsive iframe {
    width: 177.78vh;
  }
} */

</style>