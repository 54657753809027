<template>
  <div class="page">
    <div v-if="pageData.name == 'home'">
      <Home :pageData="pageData"/>
    </div>

    <div v-else-if="pageData.name == 'enquiry'">
      <Enquiry :pageData="pageData"/>
    </div>

    <div v-else-if="pageData.name == 'career-opportunities'">
      <CareerOpportunities :pageData="pageData"/>
    </div>

    <div v-else-if="pageData.name == 'facilities-and-amenities'">
      <FacilitiesAmenities :pageData="pageData"/>
    </div>

    

    <div v-else>
      <b-container fluid="xl" class="pt-3 pt-lg-5">
        <h1 class="page-title text-center">{{ pageData.title }}</h1>
      </b-container>
     
      <Section v-for="section in pageData.sections" :key="section.name" :section="section"/>
   
    </div>
  </div>
</template>

<script>
  // if (typeof this.$route.params != 'undefined') {
  //   console.log(this.$route.params);
  // }



// @ is an alias to /src
import Section from '@/views/Section.vue'
import Enquiry from '@/views/Enquiry.vue'
import CareerOpportunities from '@/views/CareerOpportunities.vue'
import FacilitiesAmenities from '@/views/FacilitiesAmenities.vue'
import Home from '@/views/Home.vue'

export default {
  name: 'Page',
  data () {
    return {
      page: this.$route.params.page,
      pageParam: '',
      pageData: '',
    }
  },
  watch: {
    '$route.params.page': function (page) {
      this.page = page;
      this.getPageContent()
    }
  },
  created () {
     this.getPageContent()
  },
  methods: {
    getPageContent() {
      if (typeof this.page !== 'undefined') {
        this.$axios
          .get(this.$apiUrl+'/find-view-url?link='+this.page+'&token='+this.$apiToken)
          .then(response => {
            this.pageParam = response.data.view_url;
            if (this.pageParam !== '') {
              this.$axios
                .get(this.$apiUrl+this.pageParam+'?token='+this.$apiToken)
                .then(response => {
                  this.pageData = response.data.page;
                }).catch(error => {
                  console.log('There was an error:' + error.response)
                })
            } else {
              console.log('404 not found');
            }

          }).catch(error => {
            console.log('There was an error:' + error.response)
          })         
      } else {
        this.$axios
          .get(this.$apiUrl+'/page/home?token='+this.$apiToken)
          .then(response => {
            this.pageData = response.data.page;
          }).catch(error => {
            console.log('There was an error:' + error.response)
          })
      }
    }
  },
  components: {
    Section,
    Home,
    Enquiry,
    CareerOpportunities,
    FacilitiesAmenities
  }
}
</script>

<style scoped>
  .page-title {
    color: #e8a713;
  }

  @media only screen and (max-width: 768px) {
    .page-title {
      color: #e8a713;
      font-size: 1.75em;
    }

  }

  
</style>