<template>
  <div class="py-3 mb-5">
    <h4 v-if="content.title_visible == true">{{ content.title }}</h4>
    <div v-html="content.body">
    </div>
  </div>
</template>


<script>
  export default {
    name: 'Article',
    props: {
      content: Object
    }
  }
</script>